import React, { useEffect, useState } from "react";
import ScrollToTopOnRouteChange from "components/ScrollToTopOnRouteChange/ScrollToTopOnRouteChange";
import { MaterialReactTable } from "material-react-table";
import { Edit, Delete } from "@mui/icons-material";
import InputField from "components/fields/InputField";
import userResources from "api/userResources";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton/SubmitButton";
import ConfirmModal from "components/ConfirmModal/ConfirmModal";
import { selectUserInfo } from "../../../redux/userSlice";
import { useSelector } from "react-redux";

export default function TransactionType() {
  const userInfo = useSelector(selectUserInfo);
  const [globalFilter, setGlobalFilter] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [editingTransactionType, setEditingTransactionType] = useState(null);
  const [disabled, setDisabled] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    slug: "",
  });
  const [errors, setErrors] = useState({});

  const getTransactionType = async (pageIndex, pageSize, globalFilter) => {
    setIsLoading(true);
    try {
      const response = await userResources.transactionType(
        pageIndex || 0,
        pageSize || 5,
        globalFilter || ""
      );
      setTransactionData(response.data.data);
      setTotalRows(response.data.paginate_data.total);
    } catch (error) {
      console.error("Error fetching Transaction Type data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const validateField = (name, value) => {
    let errorMessage = "";
    if (name === "name" && !value) {
      errorMessage = "Transaction Type name is required.";
    }
    return errorMessage;
  };

  const handleChange = (name, value) => {
    const fieldValue = value.target.value;

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: fieldValue,
      };

      if (name === "name") {
        updatedData.slug = fieldValue.toLowerCase().replace(/\s+/g, "_");
      }

      return updatedData;
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, fieldValue),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);

    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const errorMessage = validateField(key, formData[key]);
      if (errorMessage) {
        newErrors[key] = errorMessage;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fix the errors before submitting.");
      setDisabled(false);
      return;
    }

    const loadingToastId = toast.loading("Submitting...");

    try {
      if (editingTransactionType) {
        await userResources.addTransactionType({
          id: editingTransactionType.id,
          ...formData,
        });
        setEditingTransactionType(null);
        getTransactionType();
        toast.update(loadingToastId, {
          render: "Transaction Type updated successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        await userResources.addTransactionType({
          ...formData,
        });
        getTransactionType();
        toast.update(loadingToastId, {
          render: "Transaction Type added successfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }

      setFormData({
        name: "",
        slug: "",
      });
    } catch (error) {
      console.error("Error submitting form data:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to submit form. Please try again.";

      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });

      if (error.response?.data?.errors) {
        const { errors } = error.response.data;
        const filteredErrors = { ...errors };
        delete filteredErrors.slug;
        setErrors(filteredErrors);
      }
    } finally {
      setDisabled(false);
    }
  };

  const column = [
    { accessorKey: "name", header: "Transaction Type" },
    { accessorKey: "slug", header: "Slug" },
    {
      accessorKey: "created_at",
      header: "Created at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "updated_at",
      header: "Updated at",
      Cell: ({ cell }) => {
        // Extract and format the date
        const date = new Date(cell.getValue()); // Create a Date object
        const formattedDate = date.toISOString().split("T")[0]; // Get only the date part (YYYY-MM-DD)
        return <span>{formattedDate}</span>; // Display the formatted date
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <Edit
            className="cursor-pointer text-blue-500"
            onClick={() => handleEdit(row.original)}
            aria-label={`Edit ${row.original.name}`}
          />
          <div>
            {userInfo?.data?.role_id ===
            Number(process.env.REACT_APP_ROLE_EMPLOYEE) ? null : (
              <ConfirmModal
                onConfirm={handleDelete}
                title="Delete Transaction Type"
                message="Are you sure you want to delete this Transaction Type?"
                employeeId={row.original.id}
              />
            )}
          </div>
        </div>
      ),
    },
  ];

  const handleEdit = (transaction_type) => {
    setFormData({
      name: transaction_type.name,
      slug: transaction_type.slug,
    });
    setEditingTransactionType(transaction_type);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCancel = () => {
    setEditingTransactionType(null);
    setFormData({ name: "", slug: "" });
    setErrors({});
  };

  const handleDelete = async (id) => {
    const loadingToastId = toast.loading("Deleting Transaction Type...");

    try {
      await userResources.transactionTypeDelete(id);

      toast.update(loadingToastId, {
        render: "Transaction Type deleted successfully.",
        type: "success",
        isLoading: false,
        autoClose: 5000,
      });

      getTransactionType(
        pagination.pageIndex,
        pagination.pageSize,
        globalFilter
      );
    } catch (error) {
      console.error("Error deleting Transaction Type:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to delete Transaction Type. Please try again.";
      toast.update(loadingToastId, {
        render: errorMessage,
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      if (toast.isActive(loadingToastId)) {
        toast.dismiss(loadingToastId);
      }
    }
  };

  useEffect(() => {
    getTransactionType();
  }, []);

  useEffect(() => {
    getTransactionType(pagination.pageIndex, pagination.pageSize, globalFilter);
  }, [pagination, globalFilter]);

  return (
    <section className="mt-6">
      <ScrollToTopOnRouteChange />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-1">
        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            {editingTransactionType
              ? "Edit Transaction Type"
              : "Add Transaction Type"}
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 gap-2">
              <InputField
                label="Transaction Type Name"
                name="name"
                type="text"
                placeholder="Transaction Type Name"
                value={formData.name}
                onChange={(value) => handleChange("name", value)}
                state={errors.name ? "error" : ""}
                errorMessage={errors.name}
              />

              <InputField
                label="Slug"
                name="slug"
                type="text"
                placeholder="Slug"
                value={formData.slug}
                onChange={(value) => handleChange("slug", value)}
                state={errors.slug ? "error" : ""}
                errorMessage={errors.slug}
                disabled={true}
              />
            </div>
            <div className="mt-6 flex space-x-2">
              {editingTransactionType && (
                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-full rounded-lg border border-red-500 p-2 text-red-500 hover:bg-red-500 hover:text-white focus:outline-none"
                >
                  Cancel
                </button>
              )}

              <SubmitButton
                editing={editingTransactionType}
                disabled={disabled}
                add="Add Transaction Type"
                edit="Update Transaction Type"
              />
            </div>
          </form>
        </div>

        <div className="rounded-lg bg-white p-6 shadow-md dark:bg-gray-900">
          <h3 className="mb-4 text-xl font-semibold dark:text-white">
            Transaction Type List
          </h3>
          <MaterialReactTable
            columns={column}
            data={transactionData}
            state={{ pagination, isLoading }}
          />
        </div>
      </div>
    </section>
  );
}
